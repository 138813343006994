<template>
  <div class="edu-cases">
    <div style="position:relative">
      <img
        class="bannerImg"
        src="../assets/images/eduCourse/banner.png"
        alt=""
      />
      <div class="banner">
        <p>人工智能实训代码案例</p>
        <p>国产人工智能教育科研解决方案</p>
      </div>
    </div>
    <div class="paragraphs" >
      <div class="title" style="text-align:center">教育产品方案</div>
       <el-tabs v-model="activeName">
            <el-tab-pane label="火焰识别" name="0">
             </el-tab-pane>
              <el-tab-pane label="交通识别" name="1">
             </el-tab-pane>
              <el-tab-pane label="CCD识别" name="2">
             </el-tab-pane>
        </el-tabs>
        <img :src="pages[activeName].url" alt="" style="width:100%">
      
    </div>
  </div>
</template>
<script>
/* eslint-disable */
export default {
  data() {
    return {
      activeName:'0',
      index:0,
      pages:[
        {title:'',url:require('@/assets/images/eduCases/p1.png')},
        {title:'',url:require('@/assets/images/eduCases/p2.png')},
        {title:'',url:require('@/assets/images/eduCases/p3.png')},
      ]
    }
  },
  watch:{
    activeName(nv,ov){
     this.index=nv
    }
  },
  mounted() {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
  methods: {
   
  },
}
</script>
<style lang="scss" scoped>
.edu-cases {
  .banner {
    left: 10%;
    p:nth-child(1){
        display: inline-block;
        line-height: 0.5rem;
        border-bottom: 1px solid #DDE9F9;   
        }
       p:nth-child(2){
       display: block;
       border-bottom: none;
       }
  }
}
</style>
<style lang="scss" >
.edu-cases{
  .tabs {
                margin-bottom: 0.4rem;
                display: flex;
                justify-content: space-between;
                span {
                    display: inline-block;
                    width: 30%;
                    height: 0.64rem;
                    border-radius: 0.03rem;
                    background: #F7F7F7;
                    text-align: center;
                    cursor: pointer;
                    color: #0061E3;
                    line-height: 0.6rem;
                    font-size: 0.24rem;
                }
                .active {
                    background: #0061E3;
                    color: #FFFFFF;
                }
            }
  .el-tabs__item{
        font-size: 0.2rem  !important;
    }
   .el-tabs__nav {
     margin: auto;
     transform: translateX(-50%)!important;
     left:50%;
   }
   
}
    
</style>
